import store from '@/store'

const isValidPermission = (permission) => {
  var obj = permission.split('.')
  for (let i = 0; i < store.state.portal.profile.user_permissions.length; i++) {
    if (obj[1] === store.state.portal.profile.user_permissions[i].codename && obj[0] === store.state.portal.profile.user_permissions[i].content_type.app_label) {
      return true
    }
  }
  for (let x = 0; x < store.state.portal.profile.groups.length; x++) {
    for (let i = 0; i < store.state.portal.profile.groups[x].permissions.length; i++) {
      if (obj[1] === store.state.portal.profile.groups[x].permissions[i].codename && obj[0] === store.state.portal.profile.groups[x].permissions[i].content_type.app_label) {
        return true
      }
    }
  }
  return false
}


const hasPermission = (permission, rule, condition='and') => {
  // if (Object.keys(store.state.portal.profile).length === 0) {
  //   menu.push(rule)
  //   return true
  // }
  if (store.state.portal.profile.is_superuser) {
    menu.push(rule)
    return true
  }
  if (typeof permission === 'object' && condition === 'or') {
    for (let i = 0; i < permission.length; i++) {
      if (isValidPermission(permission[i])) {
        menu.push(rule)
        return true
      }
    }
    return false
  } else if (typeof permission === 'object') {
    var valid = []
    for (let i = 0; i < permission.length; i++) {
      valid.push(isValidPermission(permission[i]))
    }
    var myValid = true
    for (let i = 0; i < valid.length; i++) {
      if(valid[i] === false) {
        myValid = false
      }
    }
    if (myValid) {
      menu.push(rule)
    }
    return myValid
  } else {
    if (isValidPermission(permission)) {
      menu.push(rule)
      return true
    }
  }
}

var menu = []

hasPermission(['finance.view_frontend_finance', 'finance.view_all_finance', 'finance.view_damia_finance', 'finance.view_meta_finance'], {
  title: "Dashboard",
  route: 'home',
  icon: 'GridIcon',
}, 'or')

hasPermission(['finance.view_frontend_finance', 'finance.view_all_finance', 'finance.view_damia_finance', 'finance.view_meta_finance', 'finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'], {
  header: 'Reports',
}, 'or')
hasPermission(['finance.view_frontend_finance', 'finance.view_all_finance', 'finance.view_damia_finance', 'finance.view_meta_finance', 'finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'], {
  title: 'Finance',
  route: 'report-finance',
  icon: 'FileIcon',
}, 'or')

hasPermission(['cockpit.view_questionanswering',], {
  title: 'Cockpit AI',
  route: 'report-cockpit',
  icon: 'MessageCircleIcon',
}, 'or')

export default menu